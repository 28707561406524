.container {
  cursor: context-menu;
  height: 100vh;
  background-color: #141414;
  color: #f6511d;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.card {
  width: 70%;
}

@media (max-width: 1211px) {
  .card {
    width: 95%;
  }
}

.card-title,
.card-info {
  padding: 20px;
  border-radius: 3px;
  -webkit-box-shadow: -8px 10px 10px 5px rgba(0, 0, 0, 0.8);
  box-shadow: -8px 10px 10px 5px rgba(0, 0, 0, 0.8);
}

.card-title {
  margin-bottom: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.card-title-image {
  margin-left: auto;
}

@media (max-width: 540px) {
  .card-title {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .card-title-image {
    margin-left: 0;
    margin-bottom: 10px;
  }
  img {
    width: 170px !important;
    -o-object-fit: cover;
    object-fit: cover;
  }
}

img {
  width: 200px;
  -o-object-fit: cover;
  object-fit: cover;
}

h1 {
  font-size: 2.3rem;
}

h3 {
  font-weight: 500;
  font-size: 1.2rem;
  color: #f9eae1;
}

@media (max-width: 565px) {
  h1 {
    font-size: 1.9rem;
  }
  h3 {
    font-size: 1rem;
  }
}

@media (max-width: 474px) {
  h1 {
    font-size: 1.4rem;
  }
  h3 {
    font-size: 0.8rem;
  }
  svg,
  .social {
    font-size: 1.2rem !important;
  }
}

.personal-mobile,
.uk-mobile,
.office-mobile,
.email,
.website,
.address {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 0;
}

.mobile {
  /* margin-bottom: 4px; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.mobile-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 20px;
}

.personal-mobile,
.uk-mobile,
.office-mobile {
  padding: 5px 0;
}

#email_link,
#website_link,
p {
  margin-left: 20px;
  color: #f9eae1;
}

#personal_link,
#uk_link,
#office_link {
  color: #f9eae1;
}

#personal_link:hover,
#uk_link:hover,
#office_link:hover,
#email_link:hover,
#website_link:hover {
  color: #00a6fb;
}

/* @media (max-width: 565px) {
  a,
  p {
    margin-left: 0.3rem;
  }
} */

@media (max-width: 963px) {
  .region,
  a,
  p {
    font-size: 0.85rem;
  }
}

@media (max-width: 565px) {
  a,
  p {
    font-size: 0.8rem !important;
  }
}

a {
  text-decoration: none;
}

a:hover {
  color: #00a6fb;
}

.social {
  margin-top: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.facebook,
.instagram,
.linkedin,
.twitter {
  margin-right: 30px;
}

#fb,
#insta,
#linkedin,
#twit {
  color: #f6511d;
}

#fb:hover,
#insta:hover,
#linkedin:hover,
#twit:hover {
  color: #00a6fb;
}

.personal-mobile-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  /* margin-left: 50px; */
}

.personal-mobile-info-lb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  /* margin-left: 20px; */
}

.social-download {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.download {
  margin-top: 8px;
}

#download {
  font-size: 16px;
}

#download:hover {
  color: #00a6fb !important;
}

.download-div {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

#download {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  font-size: 0.8rem;
}

@media (max-width: 460px) {
  #download {
    font-size: 0.6rem !important;
  }
}
